import AdminUserDetails from '@/features/access-management/admin-management/components/AdminUserDetails';
import AssignRoleModal from '@/features/access-management/admin-management/components/AssignRoleModal';
import CreateAdminModal from '@/features/access-management/admin-management/components/CreateAdminModal';
import CreateRoleModal from '@/features/access-management/roles-and-permission/components/CreateRoleModal';
import EditRoleDetailsModal from '@/features/access-management/roles-and-permission/components/EditRoleDetailsModal';
import RemoveAccess from '@/features/access-management/roles-and-permission/components/RemoveAccess';
import { DeleteCrytpoInvoiceModal } from '@/features/crypto-invoice/components';
import UpdateFeaturedNotes from '@/features/site-management/flash-notes/components/UpdateFeaturedNotes';
import UpdateFlashMessage from '@/features/site-management/flash-notes/components/UpdateFlashMessage';
import EditSupportLine from '@/features/site-management/other/EditSupportLine';
import ViewEditAssetRate from '@/features/site-management/rate/components/ViewEditAssetRate';
import EditReferralForm from '@/features/site-management/referral/EditReferralForm';
import AddOrRemoveFiatBalance from '@/features/user-management/users/[userId]/userActions/AddOrRemoveFiatBalance';
import AddOrRemoveUserPointsBalance from '@/features/user-management/users/[userId]/userActions/AddOrRemoveUserPointsBalance';
import DeleteUser from '@/features/user-management/users/[userId]/userActions/DeleteUser';
import TagUserAccount from '@/features/user-management/users/[userId]/userActions/TagUserAccount';
import ApproveOrRejectWithdrawalModal from '@/features/withdrawals/components/ApproveOrRejectWithdrawalModal';
import ManualWithdrawalReversal from '@/features/withdrawals/components/ManualWithdrawalReversal';

export const modalConfig = {
	initial: 'initialModal',
	testModal: 'test_modal',
	approveOrRejectWithdrawal: 'approveOrRejectWithdrawal',
	manualWithdrawalReversal: 'manualWithdrawalReversal',
	tagUserAccount: 'tagUserAccount',
	addRemoveUserPointBalance: 'addRemoveUserPointBalance',
	deleteSingleUser: 'deleteSingleUser',
	addRemoveFiatBalance: 'addRemoveFiatBalance',
	deleteInvoice: 'deleteInvoice',
	viewEditCryotoAsset: 'viewEditCryotoAsset',
	editReferral: 'editReferral',
	editSupportLine: 'editSupportLine',
	updateFlashMessage: 'updateFlashMessage',
	updateFeaturedNote: 'updateFeaturedNote',
	createRole: 'createRole',
	createAdmin: 'createAdmin',
	editRoleDetails: 'editRoleDetails',
	showAdminUserDetails: 'showAdminUserDetails',
	assignAdminRole: 'assignAdminRole',
	removeAcess: 'removeAccess',
} as const;

export type ModalConfigKeyType = keyof typeof modalConfig;
export type ModalConfigType = typeof modalConfig;
export type ModalConfigIdType = ModalConfigType[ModalConfigKeyType];
export interface ModalConfigStateType {
	id: ModalConfigIdType;
	header?: string;
	wrapperClassName?: string;
	metadata?: unknown;
}

export const manuallyTriggeredModals: ModalConfigIdType[] = ['test_modal'];

export const modalContent: Record<ModalConfigIdType, React.ReactNode> = {
	initialModal: null,
	test_modal: null,
	approveOrRejectWithdrawal: <ApproveOrRejectWithdrawalModal />,
	manualWithdrawalReversal: <ManualWithdrawalReversal />,
	tagUserAccount: <TagUserAccount />,
	addRemoveUserPointBalance: <AddOrRemoveUserPointsBalance />,
	deleteSingleUser: <DeleteUser />,
	addRemoveFiatBalance: <AddOrRemoveFiatBalance />,
	deleteInvoice: <DeleteCrytpoInvoiceModal />,
	viewEditCryotoAsset: <ViewEditAssetRate />,
	editReferral: <EditReferralForm />,
	editSupportLine: <EditSupportLine />,
	updateFlashMessage: <UpdateFlashMessage />,
	updateFeaturedNote: <UpdateFeaturedNotes />,
	createRole: <CreateRoleModal />,
	editRoleDetails: <EditRoleDetailsModal />,
	createAdmin: <CreateAdminModal />,
	showAdminUserDetails: <AdminUserDetails />,
	assignAdminRole: <AssignRoleModal />,
	removeAccess: <RemoveAccess />,
};

export const closeModalById = (modalId: ModalConfigIdType) => {
	const modalCloseBtn = document.getElementById(`closeBtnRef-${modalId}`) as HTMLButtonElement | null;
	if (!modalCloseBtn) return;
	modalCloseBtn.click();
};
