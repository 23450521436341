import type { ToastState } from 'BreetRedux';
import { addToast, store } from 'BreetRedux';
import { KeyboardEvent } from 'react';

import { clientChannelOptions } from '../constants';
import type { UserDeviceType } from '../types';

/**
 * Helper function which limits the number of keys pressed in an input field,
 * which also allows the trigger of a callback to be called when the enter key is pressed.
 *
 * @param {*} valueLength - The length of the value typed which defaults to 4
 * @param {*} enterKeyCallback - The enterKeyCallback function to call when when the enter key is pressed
 */
export const inputKeyDownValidator =
	(valueLength = 4, enterKeyCallback?: (value: string) => void) =>
	(e: KeyboardEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const enterKey = e.which === 13 || e.key === 'Enter';
		const maxValueLength = target.value.length >= valueLength;
		const isNumericKey = /\d/.test(e.key);
		const validStringKeys = ['v'];
		const isControlKey = e.key === 'Backspace' || e.key === 'Delete' || e.ctrlKey || e.metaKey;

		// Trigger enterKeyCallback if max value length is reached
		if (maxValueLength && !isControlKey) {
			if (enterKeyCallback) enterKeyCallback(target.value);
		}

		// Prevent input if max value length is reached and key is not control key
		if (maxValueLength && !isControlKey) {
			e.preventDefault();
		}

		// Allow only numeric input, enter key, validStringKeys, or control keys (backspace, delete, cmd, ctrl)
		if (!isNumericKey && !enterKey && !isControlKey && !validStringKeys.includes(e.key.toLowerCase())) {
			e.preventDefault();
		}
	};

/**
 * A higher-order function to validate keypress events in input fields.
 * It prevents invalid characters based on a provided regex and an optional extra validation condition.
 *
 * @param {RegExp} pattern - The regular expression to test the key against.
 * @param {boolean} [additionalCondition=true] - An optional extra validation condition.
 * @returns A function that handles the `onKeyPress` event and validates input.
 */
export const validateKeyDown =
	(pattern: RegExp, additionalCondition = true) =>
	(event: React.KeyboardEvent<HTMLInputElement>) => {
		const { key } = event;
		const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'Escape'];

		if (allowedKeys.includes(key)) return;

		// Prevent keypress if it doesn't match the pattern or fails the additional condition
		if (!pattern.test(key) || !additionalCondition) {
			event.preventDefault();
		}
	};

export const getLocationFrom = () => {
	const { pathname } = window.location;
	const searchParams = window.location.search;
	return encodeURIComponent(`${pathname}${searchParams}`);
};

const getDataResponseMessage = (dataMsg: unknown) =>
	(dataMsg as { message?: string }).message ??
	(dataMsg as { data?: { message?: string } }).data?.message ??
	'Sorry, an error occurred. Please try again';

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Reuseable handler to spawn a new toast message
 */
export const spawnAppToast = ({ dataMsg, type, message = '', ...rest }: { dataMsg?: unknown } & Omit<ToastState, 'id'>) => {
	store.dispatch(
		addToast({
			type,
			message: capitalizeFirstLetter(dataMsg ? getDataResponseMessage(dataMsg) : message),
			...rest,
		})
	);
};

/**
 * Generic helper function that returns array of numbers making it easier to identify
 * elements instead of using array index as keys
 * @param arrayLength -number
 */
export const mapArrayFromLength = (arrayLength: number) => Array.from({ length: arrayLength }).map((_, index) => index + 1);

/**
 * Copies the given text to the clipboard.
 *
 * @param {string} text The text to copy to the clipboard.
 */
export const copyTextToClipboard =
	({ text, id, message }: { text?: string; id?: string; message?: string }) =>
	async () => {
		if (!text) return;
		try {
			await navigator.clipboard.writeText(text);
			store.dispatch(
				addToast({
					type: 'success',
					message: message ?? `Copied ${id ?? 'text'} to clipboard`,
				})
			);
		} catch (error) {
			console.error(error);
			store.dispatch(
				addToast({
					type: 'error',
					message: message ?? `An error occured while trying to copy ${id ?? 'text'} to clipboard`,
				})
			);
		}
	};

export const getChannelOption = (deviceId?: UserDeviceType) => clientChannelOptions.find((channel) => channel.id === deviceId);

/**
 * Checks if there's a change between form's inital/default values and the current form values.
 *
 * @param option.defaultValues - The first value to compare.
 * @param option.currentValues - The second value to compare.
 * @returns  True if there are change(s), false otherwise.
 */
export const formHasChanges = <S, T>({ defaultValues, currentValues }: { defaultValues: S; currentValues: T }) =>
	JSON.stringify(defaultValues) !== JSON.stringify(currentValues);

/**
 * Performs a deep merge of two objects.
 * The properties of the source object will override the target object in a deeply nested manner.
 *
 * @param target - The target object to merge into.
 * @param source - The source object whose properties are to be merged.
 * @returns The resulting object after merging.
 */
export const deepMerge = <T extends Record<string, unknown>>(target?: T, source?: unknown) => {
	if (!source) return target;
	if (!target) return source;

	const result: Record<string, unknown> = { ...target };

	Object.entries(source).forEach(([key, value]) => {
		const targetValue = target[key];

		if (typeof targetValue === 'object' && typeof value === 'object') {
			// Recursively merge if the property is an object in both target and source
			result[key] = deepMerge(targetValue as T, value as T);
		} else {
			result[key] = value as T;
		}
	});

	return result as T;
};

/**
 * Finds the first key in a Record<string, boolean> where the value is true.
 *
 * @param {Record<string, boolean>} obj - An object with string keys and boolean values.
 * @returns {string | undefined} - The first key with a truthy (true) value, or undefined if none found.
 */
export const findTruthyObjectKey = (obj?: Record<string, boolean>) => {
	if (!obj) return undefined;

	const truthyEntry = Object.entries(obj).find(([_, value]) => value);
	return truthyEntry ? truthyEntry[0] : undefined;
};

/**
 * Sets the value of the specified key to true and all other keys to false.
 *
 * @param {string} selectedKey - The key whose value should be set to true.
 * @param {Record<string, boolean>} obj - An object with string keys and boolean values.
 * @returns - A new object with the specified key set to true and others set to false.
 */
export const setObjectKeyToTrue = (selectedKey: string, obj?: Record<string, boolean>) => {
	const result: Record<string, boolean> = {};
	if (!obj) return result;

	Object.keys(obj).forEach((key) => {
		result[key] = key === selectedKey;
	});

	return result;
};
