import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateAdminRoleMutation } from '@/redux/slices/api/access.api';

import { AdminRoleFormSchema, type AdminRoleFormSchemaType } from './AdminRole.helpers';

const CreateRoleModal = () => {
	const [createAdminRole, { isLoading: isCreatingAdminRole }] = useCreateAdminRoleMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(AdminRoleFormSchema),
	});

	const handleCreateAdminRole = useCallback(
		(payload: AdminRoleFormSchemaType) => {
			const { name, description } = payload;
			createAdminRole({ name, description })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('createRole');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[createAdminRole]
	);
	return (
		<form
			className='modalFormWrapper'
			onSubmit={handleSubmit(handleCreateAdminRole)}
		>
			<Input
				label='Role'
				placeholder='E.g. Transaction Lead'
				register={register('name')}
				errorMsg={errors.name?.message}
			/>
			<TextareaInput
				label='Description'
				placeholder=''
				register={register('description')}
				errorMsg={errors.description?.message}
			/>
			<Button
				size='large'
				variant='blue'
				loading={isCreatingAdminRole}
			>
				Create
			</Button>
		</form>
	);
};

export default CreateRoleModal;
