import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { type IAdminRole, spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useUpdateAdminRoleMutation } from '@/redux/slices/api/access.api';

import { AdminRoleFormSchema, type AdminRoleFormSchemaType } from './AdminRole.helpers';

const EditRoleDetailsModal = () => {
	const modals = useAppSelector(selectModals);

	const currentModal = modals.find((modal) => modal.id === 'editRoleDetails');
	const modalMetadata = currentModal?.metadata as IAdminRole & { roleId: string };

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(AdminRoleFormSchema),
		defaultValues: {
			name: modalMetadata.name,
			description: modalMetadata.description,
		},
	});

	const [updateAdminRole, { isLoading }] = useUpdateAdminRoleMutation();

	const hanldeUpdateAdminRole = useCallback(
		(payload: AdminRoleFormSchemaType) => {
			updateAdminRole({ roleId: modalMetadata.roleId, body: payload })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ message: resp.message, type: 'success' });
					closeModalById('editRoleDetails');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[modalMetadata, updateAdminRole]
	);

	return (
		<form
			className='modalFormWrapper'
			onSubmit={handleSubmit(hanldeUpdateAdminRole)}
		>
			<Input
				label='Role'
				placeholder='E.g. Transaction Lead'
				register={register('name')}
				errorMsg={errors.name?.message}
			/>
			<TextareaInput
				label='Description'
				placeholder=''
				register={register('description')}
				errorMsg={errors.description?.message}
			/>
			<Button
				size='large'
				variant='blue'
				loading={isLoading}
			>
				Save
			</Button>
		</form>
	);
};

export default EditRoleDetailsModal;
