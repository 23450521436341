import { TableActionPopover } from 'BreetComponents';
import type { RouteParamsType } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import {
	openModal,
	selectGetSingleUser,
	useAppDispatch,
	useAppSelector,
	useFreezeUnfreezeUserMutation,
	useLazyGetUserFinanceSummaryQuery,
	useRecheckUserAMLMutation,
	useResendUserEmailVerificationMutation,
	useRestoreSingleUserMutation,
	useSuspendReinstateUserMutation,
	useTagUntagUserMutation,
	useVerifyUserEmailMutation,
} from 'BreetRedux';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const UserDetailsAction = () => {
	const { userId } = useParams<RouteParamsType>();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { data: userData } = useAppSelector(selectGetSingleUser(userId));
	const [fetchFinanceSUmmary, { isFetching: isLoadingUserSummary }] = useLazyGetUserFinanceSummaryQuery();
	const [freezeUnfreezeUser, { isLoading: isFreezingUnfreezingUser }] = useFreezeUnfreezeUserMutation();
	const [suspendReinstateUser, { isLoading: isSuspendingReinstatingUser }] = useSuspendReinstateUserMutation();
	const [verifyEmail, { isLoading: isVerifyingEmail }] = useVerifyUserEmailMutation();
	const [resendEmailVerification, { isLoading: isResendingEmailVerification }] = useResendUserEmailVerificationMutation();
	const [restoreSingleUser, { isLoading: isRestoringUser }] = useRestoreSingleUserMutation();
	const [recheckUserAML, { isLoading: usCheckingUserAML }] = useRecheckUserAMLMutation();
	const [untagAccount, { isLoading: isUntaggingAccount }] = useTagUntagUserMutation();

	const isUserFrozen = !!userData?.data?.freeze;
	const isUserTagged = !!userData?.data?.tag.status;
	const isUserSuspended = !!userData?.data?.suspended;
	const isUserDeleted = !!userData?.data?.deletedAt;

	const handleEmailVerification = useCallback(() => {
		if (!userId) return;

		verifyEmail(userId)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [userId, verifyEmail]);

	const handleResendEmailVerification = useCallback(() => {
		if (!userId) return;

		resendEmailVerification(userId)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [userId, resendEmailVerification]);

	const handleTagAccount = useCallback(() => {
		dispatch(
			openModal({
				id: 'tagUserAccount',
				header: 'Tag Account',
				metadata: { note: userData?.data?.tag.note, userId },
			})
		);
	}, [dispatch, userData?.data?.tag.note, userId]);

	const handleUntagAccount = useCallback(() => {
		if (!userId) return;

		untagAccount({ userId, tag: { note: '', status: false }, isUntagging: true })
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [untagAccount, userId]);

	const handleFreezeOrUnfreezeUser = useCallback(() => {
		if (!userId) return;

		freezeUnfreezeUser({ userId, isUserFrozen })
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [freezeUnfreezeUser, isUserFrozen, userId]);

	const handleSuspendReinstateUser = useCallback(() => {
		if (!userId) return;

		suspendReinstateUser({ userId, isUserSuspended })
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [suspendReinstateUser, isUserSuspended, userId]);

	const handleAddRemoveUserPoints = useCallback(
		(isAdding?: boolean) => () => {
			fetchFinanceSUmmary(userId)
				.unwrap()
				.then((resp) => {
					dispatch(
						openModal({
							id: 'addRemoveUserPointBalance',
							header: `${isAdding ? 'Credit' : 'Debit'} Reward Point(s)`,
							metadata: { rewardPoints: resp.data?.referralInfo, isAdding, userId },
						})
					);
				})
				.catch((err: unknown) => {
					console.error(err);
				});
		},
		[dispatch, fetchFinanceSUmmary, userId]
	);

	const handleDeleteOrRestoreUser = useCallback(() => {
		if (isUserDeleted) {
			restoreSingleUser(userId)
				.unwrap()
				.then((resp) => {
					spawnAppToast({ message: resp.message, type: 'success' });
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		} else {
			dispatch(
				openModal({
					id: 'deleteSingleUser',
					header: 'Deleting User',
					metadata: { userId },
				})
			);
		}
	}, [dispatch, isUserDeleted, restoreSingleUser, userId]);

	const handleEditUser = useCallback(() => {
		navigate(`/user-management/users/${userId}/edit`, { state: { pathname } });
	}, [navigate, pathname, userId]);

	const handleAddOrRemoveFiatBalance = useCallback(
		(isAddingFiat?: boolean) => () => {
			dispatch(
				openModal({
					id: 'addRemoveFiatBalance',
					header: `${isAddingFiat ? 'Credit' : 'Debit'} Fiat Balance`,
					metadata: {
						userId,
						currency: userData?.data?.wallet?.currency,
						balance: userData?.data?.wallet?.balance,
						isAddingFiat,
					},
				})
			);
		},
		[dispatch, userData?.data?.wallet, userId]
	);

	const handleRecheckUserAML = useCallback(() => {
		if (!userId) return;

		recheckUserAML(userId)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [recheckUserAML, userId]);

	return (
		<div className='userDetails_action'>
			<TableActionPopover
				triggerVariant='gray'
				actionItems={[
					{ title: 'Verify User Email', isLoading: isVerifyingEmail, onClick: handleEmailVerification },
					{
						title: 'Resend Verification Mail',
						isLoading: isResendingEmailVerification,
						onClick: handleResendEmailVerification,
					},
					{ title: 'Tag Account', onClick: handleTagAccount },
					...(isUserTagged ? [{ title: 'Untag Account', isLoading: isUntaggingAccount, onClick: handleUntagAccount }] : []),
					{
						title: isUserFrozen ? 'Unfreeze Account' : 'Freeze Account',
						isLoading: isFreezingUnfreezingUser,
						onClick: handleFreezeOrUnfreezeUser,
					},
					{
						title: isUserSuspended ? 'Reinstate Account' : 'Suspend Account',
						isLoading: isSuspendingReinstatingUser,
						onClick: handleSuspendReinstateUser,
					},
					{ title: 'Credit Reward Points', isLoading: isLoadingUserSummary, onClick: handleAddRemoveUserPoints(true) },
					{ title: 'Debit Reward Points', isLoading: isLoadingUserSummary, onClick: handleAddRemoveUserPoints() },
					{ title: 'Recheck AML Dojah', isLoading: usCheckingUserAML, onClick: handleRecheckUserAML },
					{ title: 'Credit Fiat Balance', onClick: handleAddOrRemoveFiatBalance(true) },
					{ title: 'Debit Fiat Balance', onClick: handleAddOrRemoveFiatBalance() },
					{ title: 'Edit User Info', onClick: handleEditUser },
					{
						title: isUserDeleted ? 'Restore User Account' : 'Delete User Account',
						isLoading: isRestoringUser,
						onClick: handleDeleteOrRestoreUser,
					},
				]}
			/>
		</div>
	);
};

export default UserDetailsAction;
