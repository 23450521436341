import type { DropdownOptionType } from '../Dropdown/Dropdown.helpers';
import type { IPagination } from '../Pagination';
import type { ITable } from '../Table';

export type TableHeaderFilterItemOptions = DropdownOptionType & Record<string, string | number | boolean>;
export interface TableHeaderFilterListType {
	id: string;
	label: string;
	placeholder?: string;
	itemOptions: TableHeaderFilterItemOptions[];
}

export interface IDataTableHeader {
	filterList?: TableHeaderFilterListType[];
	actions?: React.ReactNode;
	onSelectedFilterChange?: (selectedItems?: Record<string, DropdownOptionType | undefined>) => void;
}

export interface IDataTable<E> extends ITable<E> {
	className?: string;
	paginate?: {
		prevPage: number | null;
		nextPage: number | null;
		hasPrevPage: boolean;
		hasNextPage: boolean;
		pages: number;
		page: number;
		totalDocs: number;
	};
	onPageChange?: (state: Partial<IPagination>) => void;
	filter?: IDataTableHeader;
}

/**
 * Reshapes the selected filters object by extracting the 'value' property from each DropdownOptionType.
 *
 * @param {Record<string, DropdownOptionType>} [selectedFilters] - The object containing selected filters.
 * @returns A new object with the same keys but values replaced by their 'value' property.
 */
export const reshapeSelectedFilters = (selectedFilters?: Record<string, DropdownOptionType>) => {
	if (!selectedFilters) return {};

	return Object.keys(selectedFilters).reduce<Record<string, string>>((acc, key) => {
		acc[key] = selectedFilters[key].value as string;
		return acc;
	}, {});
};

/**
 * Filters out entries with undefined or empty string values from the input object.
 *
 * @param {Record<string, string | undefined>} input - The object to be filtered.
 * @returns A new object containing only the entries with non-empty string values.
 */
export const sanitizeObjectEntries = (input?: Record<string, string | string[] | number | boolean | undefined>) =>
	Object.entries(input ?? {}).reduce<Record<string, string | string[] | number | boolean>>((acc, [key, value]) => {
		if (value !== undefined && value !== '') {
			acc[key] = value;
		}
		return acc;
	}, {});

/**
 * Generates filter data with the given options.
 *
 * @param {string} id - The ID of the filter category.
 * @param {string} label - The label of the filter category.
 * @param {TableHeaderFilterItemOptions[]} itemOptions - The options available in the filter category.
 * @returns - Array of filter data objects.
 */
export const createFilterData = (
	id: string,
	label: string,
	itemOptions: TableHeaderFilterItemOptions[],
	placeholder?: string
) => ({
	id,
	label,
	itemOptions,
	placeholder,
});
