export const useCurrencyOutput =
	() =>
	(value: string | number = 0, round = 2, minimumRoundNum = 0, conversionRate = 1) => {
		const valueIsNumber = Number(value) / conversionRate;

		return valueIsNumber.toLocaleString(undefined, {
			minimumFractionDigits: minimumRoundNum,
			maximumFractionDigits: round,
		});
	};
