import { Button, Text } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useReverseWithdrawalMutation } from 'BreetRedux';
import { useCallback } from 'react';

const ManualWithdrawalReversal = () => {
	const modals = useAppSelector(selectModals);

	const [reverseWithdrawal, { isLoading }] = useReverseWithdrawalMutation();

	const currentModal = modals.find((modal) => modal.id === 'manualWithdrawalReversal');
	const modalMetadata = currentModal?.metadata as Record<string, string[]> | undefined;
	const isMultipleWithdrawals = modalMetadata ? modalMetadata.withdrawalIds.length > 1 : false;

	const handleReverseWithdrawal = useCallback(() => {
		if (!modalMetadata?.withdrawalIds) return;

		const { withdrawalIds } = modalMetadata;

		reverseWithdrawal(withdrawalIds)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ dataMsg: resp, type: 'success' });
				closeModalById('manualWithdrawalReversal');
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [modalMetadata, reverseWithdrawal]);

	return (
		<div className='modalFormWrapper'>
			<Text variant='body_small_regular'>
				Are you certain that {isMultipleWithdrawals ? 'these payments were' : 'this payment was'} reversed to our position?
			</Text>

			<Button
				loading={isLoading}
				size='large'
				onClick={handleReverseWithdrawal}
			>
				Yes, Reverse
			</Button>
		</div>
	);
};

export default ManualWithdrawalReversal;
