import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { EyeIcon } from '@/assets/icons';
import { useCreateAdminUserMutation } from '@/redux/slices/api/access.api';

import { AddAdminFormSchema, type AddAdminFormSchemaType } from './AddAdmin.helpers';

const CreateAdminModal = () => {
	const [visibility, setVisibility] = useState({
		password: false,
		confirmPassword: false,
	});

	const [createAdminUser, { isLoading: isCreatingAdminUser }] = useCreateAdminUserMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<AddAdminFormSchemaType>({
		resolver: yupResolver(AddAdminFormSchema),
	});

	const togglePasswordVisibility = useCallback(
		(field: 'password' | 'confirmPassword') => () => {
			setVisibility((prev) => ({
				...prev,
				[field]: !prev[field],
			}));
		},
		[]
	);

	const handleCreateAdminUser = useCallback(
		(payload: AddAdminFormSchemaType) => {
			const { name, email, username, password } = payload;
			createAdminUser({ name, email, username, password })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('createAdmin');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[createAdminUser]
	);
	return (
		<form
			className='modalFormWrapper'
			onSubmit={handleSubmit(handleCreateAdminUser)}
		>
			<Input
				label='Full Name'
				placeholder='E.g. John Doe'
				register={register('name')}
				errorMsg={errors.name?.message}
			/>
			<Input
				label='Username'
				placeholder='Enter a username of your choice'
				register={register('username')}
				errorMsg={errors.username?.message}
			/>
			<Input
				label='Email Address'
				placeholder='E.g. johndoe@ttt.com'
				register={register('email')}
				errorMsg={errors.email?.message}
			/>
			<Input
				label='Password'
				type={visibility.password ? 'text' : 'password'}
				placeholder='Enter a password'
				register={register('password')}
				errorMsg={errors.password?.message}
				suffixIcon={
					<button
						type='button'
						className='login_form_togglePassword'
						onClick={togglePasswordVisibility('password')}
					>
						<EyeIcon type={!visibility.password ? 'close' : ''} />
					</button>
				}
			/>
			<Input
				label='Confirm Password'
				type={visibility.confirmPassword ? 'text' : 'password'}
				placeholder='Re-enter your password'
				register={register('confirmPassword')}
				errorMsg={errors.confirmPassword?.message}
				suffixIcon={
					<button
						type='button'
						className='login_form_togglePassword'
						onClick={togglePasswordVisibility('confirmPassword')}
					>
						<EyeIcon type={!visibility.confirmPassword ? 'close' : ''} />
					</button>
				}
			/>
			<Button
				size='large'
				variant='blue'
				loading={isCreatingAdminUser}
			>
				Create Admin
			</Button>
		</form>
	);
};

export default CreateAdminModal;
