import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast, validateKeyDown } from 'BreetHelpers';
import { useCurrencyOutput } from 'BreetHooks';
import { selectModals, useAppSelector, useUpdateUserFiatBalanceMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import type { AddOrRemoveFiatPointsSchemaType } from './userActions.helpers';
import { AddOrRemoveFiatPointsSchema } from './userActions.helpers';

const AddOrRemoveFiatBalance = () => {
	const modals = useAppSelector(selectModals);
	const currencyOutput = useCurrencyOutput();

	const [updateUserFiatBalance, { isLoading }] = useUpdateUserFiatBalanceMutation();

	const currentModal = modals.find((modal) => modal.id === 'addRemoveFiatBalance');
	const modalMetadata = currentModal?.metadata as Record<string, string | number | boolean> | undefined;
	const isAddingFiat = !!modalMetadata?.isAddingFiat;

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver(AddOrRemoveFiatPointsSchema),
		defaultValues: {
			currency: String(modalMetadata?.currency ?? ''),
		},
	});

	const onSubmit = useCallback(
		(payload: AddOrRemoveFiatPointsSchemaType) => {
			if (!modalMetadata) return;

			updateUserFiatBalance({ userId: modalMetadata.userId, isAddingFiat, ...payload })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('addRemoveFiatBalance');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[isAddingFiat, modalMetadata, updateUserFiatBalance]
	);

	const handleNgnInputFiatChange = useCallback(
		(value: string) => {
			const rawValue = value.replace(/,/g, ''); // Remove commas for validation
			setValue('amount', Number(rawValue));
		},
		[setValue]
	);

	const currentValues = watch();

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Input
				label='Current Fiat Balance'
				placeholder='0.00'
				extValue={currencyOutput(modalMetadata?.balance as string | undefined)}
				disabled
			/>
			<Input
				label={`${isAddingFiat ? 'Add to' : 'Remove'} Balance`}
				placeholder='0.00'
				inputMode='numeric'
				extValue={currencyOutput(currentValues.amount)}
				onChange={handleNgnInputFiatChange}
				onKeyDown={validateKeyDown(/^[0-9,]$/)}
				errorMsg={errors.amount?.message}
			/>
			<TextareaInput
				label='Description'
				placeholder='Description'
				rows={3}
				register={register('reason')}
				errorMsg={errors.reason?.message}
			/>
			<Button
				size='large'
				variant={isAddingFiat ? 'main' : 'red'}
				loading={isLoading}
			>
				{isAddingFiat ? 'Add' : 'Remove'}
			</Button>
		</form>
	);
};

export default AddOrRemoveFiatBalance;
