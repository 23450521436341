import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import type { IApiFormErrorResponse } from 'BreetHelpers';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useApproveOrRejectWithdrawalMutation, useAppSelector } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import type { ApproveOrRejectWithdrawalFormKeys, ApproveOrRejectWithdrawalSchemaType } from '../withdrawals.helpers';
import { ApproveOrRejectWithdrawalSchema } from '../withdrawals.helpers';

const ApproveOrRejectWithdrawalModal = () => {
	const modals = useAppSelector(selectModals);

	const [approveOrRejectWithdrawal, { isLoading }] = useApproveOrRejectWithdrawalMutation();

	const currentModal = modals.find((modal) => modal.id === 'approveOrRejectWithdrawal');
	const modalMetadata = currentModal?.metadata as Record<string, string | string[]> | undefined;
	const isApproving = !!modalMetadata?.approved;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({ resolver: yupResolver(ApproveOrRejectWithdrawalSchema) });

	const onSubmit = useCallback(
		(payload: ApproveOrRejectWithdrawalSchemaType) => {
			const newPayload = { ...modalMetadata };

			const isGhsWithdrawal = newPayload.currency === 'ghs';
			delete newPayload.isDetailsPage;
			delete newPayload.currency;

			approveOrRejectWithdrawal({ ...payload, ...newPayload, ...(isGhsWithdrawal ? { provider: 'manual' } : {}) })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					if (modalMetadata?.isDetailsPage) window.history.back();
					closeModalById('approveOrRejectWithdrawal');
				})
				.catch((err: unknown) => {
					const errorObj = err as IApiFormErrorResponse;
					spawnAppToast({ dataMsg: err, type: 'error' });
					errorObj.data.errors.forEach((error) => {
						setError(error.field as ApproveOrRejectWithdrawalFormKeys, { message: error.message });
					});
				});
		},
		[approveOrRejectWithdrawal, modalMetadata, setError]
	);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<TextareaInput
				label={isApproving ? 'Transfer Comment' : 'Rejection Reason'}
				placeholder={isApproving ? 'Comment' : 'Reason'}
				register={register('reason')}
				errorMsg={errors.reason?.message}
			/>
			<Button
				size='large'
				loading={isLoading}
				variant={isApproving ? 'main' : 'red'}
			>
				{isApproving ? 'Approve' : 'Reject'}
			</Button>
		</form>
	);
};

export default ApproveOrRejectWithdrawalModal;
