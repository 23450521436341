import { type ISettingsObjKey, spawnAppToast } from 'BreetHelpers';
import { useUpdateSettingsMutation } from 'BreetRedux';
import { useCallback } from 'react';

interface HandleUpdateSettingsType {
	key: ISettingsObjKey;
	value: unknown;
	onSuccess?: () => void;
	onError?: () => void;
}

export const useUpdateSettingsHandler = () => {
	const [updateSettings, { isLoading }] = useUpdateSettingsMutation();

	const handleUpdateSettings = useCallback(
		({ key, value, onSuccess, onError }: HandleUpdateSettingsType) => {
			updateSettings({ key, value })
				.unwrap()
				.then((resp) => {
					onSuccess?.();
					spawnAppToast({ dataMsg: resp, type: 'success' });
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
					onError?.();
				});
		},
		[updateSettings]
	);

	return {
		isLoading,
		handleUpdateSettings,
	};
};
