import {
	type IApiDynamicPayload,
	type IApiPaginatedFilterQuery,
	type IApiResponse,
	type IApiResponsePaginated,
	type IUserRewards,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const rewardsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllUsersRewards: builder.query<IApiResponsePaginated<IUserRewards[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/payments/rewards', params }),
		}),
		getUserRewards: builder.query<IApiResponsePaginated<IUserRewards[]>, IApiPaginatedFilterQuery & { userId?: string }>({
			query: ({ userId, ...params }) => ({ url: `/admin/payments/user/${userId}/rewards`, params }),
			providesTags: ['GetUserRewards'],
		}),
		getRewardDetails: builder.query<IApiResponse<IUserRewards>, string | undefined>({
			query: (txReference) => ({ url: `/admin/payments/reward/${txReference}` }),
		}),
		updateUserRewardPoints: builder.mutation<IApiResponse, Record<string, IApiDynamicPayload>>({
			query: ({ userId, isAddingPoints, ...body }) => ({
				method: isAddingPoints ? 'PUT' : 'PATCH',
				url: `/admin/payments/user/${userId}/reward`,
				body,
			}),
			invalidatesTags: ['SingleUser'],
		}),
	}),
});

export const { useGetAllUsersRewardsQuery, useGetUserRewardsQuery, useGetRewardDetailsQuery, useUpdateUserRewardPointsMutation } =
	rewardsApiSlice;
