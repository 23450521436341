import { Button } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector } from 'BreetRedux';
import { useCallback } from 'react';

import { useRemoveAccessMutation } from '@/redux/slices/api/access.api';

const RemoveAccess = () => {
	const modals = useAppSelector(selectModals);

	const [removeAccess, { isLoading }] = useRemoveAccessMutation();

	const currentModal = modals.find((modal) => modal.id === 'removeAccess');
	const removeAccessMetaData = currentModal?.metadata as { id: string; isRemovePermission: boolean };

	const handleRemoveAccess = useCallback(() => {
		removeAccess({ id: [removeAccessMetaData.id], isRemovePermission: removeAccessMetaData.isRemovePermission })
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
				closeModalById('removeAccess');
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [removeAccessMetaData.id, removeAccessMetaData.isRemovePermission, removeAccess]);

	return (
		<div className='removeAccess__modalBlock'>
			<h4>Do you want to continue this action?</h4>
			<Button
				size='large'
				variant='red'
				loading={isLoading}
				onClick={handleRemoveAccess}
			>
				Remove
			</Button>
		</div>
	);
};

export default RemoveAccess;
