import { Button, sanitizeObjectEntries, SearchInput, SkeletonLoader } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { SORT_LATEST_TO_OLDEST, spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector } from 'BreetRedux';
import { useCallback, useState } from 'react';

import { useAssignRoleToAdminMutation, useGetAdminRolesQuery, useGetSingleAdminUserQuery } from '@/redux/slices/api/access.api';

import { transformedAdminUserDataToObject } from './AddAdmin.helpers';

const AssignRoleModal = () => {
	const [selectedRoleId, setSelectedRoleId] = useState<string>('');
	const [searchRoleValue, setSearchRoleValue] = useState<string>('');
	const modals = useAppSelector(selectModals);
	const currentModal = modals.find((modal) => modal.id === 'assignAdminRole');
	const addRoleMetaData = currentModal?.metadata as { userId: string; hasRole: boolean };
	const { data: adminUserData, isFetching } = useGetSingleAdminUserQuery(addRoleMetaData.userId, {
		refetchOnMountOrArgChange: true,
	});
	const adminUserObject = transformedAdminUserDataToObject(adminUserData?.data ?? []);
	const adminUser = adminUserObject[addRoleMetaData.userId];
	const { data: adminRoleQueryData, isFetching: isFetchingRoles } = useGetAdminRolesQuery(
		{
			page: 1,
			size: 200,
			sort: JSON.stringify(sanitizeObjectEntries({ createdAt: SORT_LATEST_TO_OLDEST })),
		},
		{ refetchOnMountOrArgChange: true }
	);

	const filteredRoles = adminRoleQueryData?.data?.filter((item) =>
		item.name.toLowerCase().includes(searchRoleValue.toLowerCase())
	);

	const [assignAdminRole, { isLoading: assigningRole }] = useAssignRoleToAdminMutation();

	const handleItemClick = useCallback(
		(id: string) => () => {
			setSelectedRoleId(id);
		},
		[]
	);

	const onAssignAdminRole = useCallback(() => {
		assignAdminRole({ adminId: [addRoleMetaData.userId], roleId: selectedRoleId, hasRole: addRoleMetaData.hasRole })
			.unwrap()
			.then((resp) => {
				spawnAppToast({ dataMsg: resp, type: 'success' });
				closeModalById('assignAdminRole');
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [assignAdminRole, addRoleMetaData.userId, addRoleMetaData.hasRole, selectedRoleId]);

	const handleRoleSearchChange = useCallback((value: string) => {
		setSearchRoleValue(value);
	}, []);

	return (
		<>
			<div className='assign-role'>
				{!isFetching && adminUser ? (
					<div className='assignRole__owner'>
						<span>Assign new role to:</span>
						<span>{adminUser.userInfo.name}</span>
					</div>
				) : null}

				<div className='assignRole_searchBlock'>
					<SearchInput
						onChange={handleRoleSearchChange}
						placeholder='Search by role name'
						size='small'
					/>
				</div>
				{!isFetching && adminUser ? (
					<div className='assignRole__current'>
						<span>Current Role</span>
						<span>{adminUser.role.name}</span>
					</div>
				) : null}
				{isFetchingRoles ? (
					<div className='assignRole__loadingBlock'>
						{Array.from({ length: 5 }, (_, key) => key + 1).map((item) => (
							<SkeletonLoader
								key={item}
								type='square'
								width='100%'
								height='5rem'
							/>
						))}
					</div>
				) : (
					<ul className='role-list'>
						{filteredRoles?.map((role) => (
							<li
								key={role._id}
								className='role-item'
							>
								<button
									type='button'
									onClick={handleItemClick(role._id)}
									className={role._id === selectedRoleId ? 'selected' : ''}
								>
									<span className='roleItem__name'>{role.name}</span>
									<span className='roleItem__desc'>{role.description}</span>
								</button>
							</li>
						))}
					</ul>
				)}
			</div>
			<div className='assignRole-btnBlock'>
				<Button
					size='large'
					type='button'
					variant='blue'
					onClick={onAssignAdminRole}
					loading={assigningRole}
				>
					Proceed
				</Button>
			</div>
		</>
	);
};

export default AssignRoleModal;
