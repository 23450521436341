import { useDebounce } from 'BreetHooks';
import { useCallback, useEffect, useState } from 'react';

import { CloseIcon, SearchIcon } from '@/assets/icons';

import Input from './Input';
import type { ISearchInput } from './Input.heper';

const SearchInput = ({ isSearching, clearText, suffixIcon, onChange, ...rest }: ISearchInput) => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [isTyping, setIsTyping] = useState<boolean>(false);

	const debouncedSearchValue = useDebounce(searchValue, 500);
	const isLoading = isSearching ?? isTyping;

	useEffect(() => {
		if (onChange) {
			onChange(debouncedSearchValue);
		}
		setIsTyping(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue]);

	const onInputChangeHandler = useCallback((val: string) => {
		setSearchValue(val);
		setIsTyping(true);
	}, []);

	const handleClearText = useCallback(() => {
		setSearchValue('');
	}, []);

	return (
		<Input
			prefixIcon={isLoading ? <div className='isloading' /> : <SearchIcon />}
			suffixIcon={
				clearText && !!searchValue ? (
					<button
						type='button'
						onClick={handleClearText}
						className='searchInput_cleartext'
					>
						<CloseIcon />
					</button>
				) : (
					suffixIcon
				)
			}
			extValue={searchValue}
			wrapperClassName='searchInput'
			onChange={onInputChangeHandler}
			{...rest}
		/>
	);
};

export default SearchInput;

SearchInput.displaName = 'BreetSearchInput';
