import {
	type IAdminPermission,
	type IAdminResources,
	type IAdminRole,
	type IAdminUserAccess,
	type IAdminUsers,
	type IApiPaginatedFilterQuery,
	type IApiResponse,
	type IApiResponsePaginated,
	type IPermissionPayloadType,
} from 'BreetHelpers';

import { type AdminRoleFormSchemaType } from '@/features/access-management/roles-and-permission/components/AdminRole.helpers';

import { apiSlice } from './apiSlice';

export const accessApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAdminRoles: builder.query<IApiResponsePaginated<IAdminRole[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/access/roles',
				params,
			}),
			providesTags: ['GetAdminRoles'],
		}),
		getAdminRoleDetails: builder.query<IApiResponse<IAdminRole>, string | undefined>({
			query: (roleId) => ({
				url: `/admin/access/roles/role/${roleId}`,
			}),
		}),
		createAdminRole: builder.mutation<IApiResponse, Partial<{ name: string; description: string }>>({
			query: ({ name, description }) => ({
				method: 'POST',
				url: '/admin/access/roles',
				body: {
					name,
					description,
				},
			}),
			invalidatesTags: ['GetAdminRoles'],
		}),
		updateAdminRole: builder.mutation<IApiResponse<IAdminRole>, { roleId?: string; body: AdminRoleFormSchemaType }>({
			query: ({ roleId, body }) => ({
				method: 'PUT',
				url: `/admin/access/roles/role/${roleId}`,
				body,
			}),
			invalidatesTags: ['GetAdminRoles'],
		}),
		assignRoleToAdmin: builder.mutation<IApiResponse, { adminId: string[]; roleId: string; hasRole: boolean }>({
			query: ({ adminId, roleId, hasRole }) => ({
				method: hasRole ? 'PUT' : 'POST',
				url: '/admin/access/users',
				body: {
					_ids: adminId,
					_id: roleId,
				},
			}),
			invalidatesTags: ['GetAllAdminUsers'],
		}),
		removeAdminRole: builder.mutation<IApiResponse, { adminId: string[]; roleId: string }>({
			query: ({ adminId, roleId }) => ({
				method: 'PATCH',
				url: '/admin/access/users',
				body: {
					_ids: adminId,
					_id: roleId,
				},
			}),
			invalidatesTags: ['GetAllAdminUsers'],
		}),
		removeAccess: builder.mutation<IApiResponse, { id: string[]; isRemovePermission: boolean }>({
			query: ({ id, isRemovePermission }) => ({
				method: isRemovePermission ? 'PATCH' : 'PUT',
				url: '/admin/access/users/nuke',
				body: {
					_ids: id,
				},
			}),
			invalidatesTags: ['GetAdminRoles', 'GetResourcePermissions'],
		}),
		getAllResources: builder.query<IApiResponse<IAdminResources[]>, void>({
			query: () => ({
				url: '/admin/resources',
			}),
			providesTags: ['GetAllResources'],
		}),
		getResourcePermissions: builder.query<IApiResponsePaginated<IAdminPermission[]>, string | undefined>({
			query: (pathURL) => ({
				url: `/admin/access/permissions/?filter[path]=${pathURL}`,
			}),
			providesTags: ['GetResourcePermissions'],
		}),
		getAdminUsers: builder.query<IApiResponsePaginated<IAdminUserAccess[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/access/admins',
				params,
			}),
			providesTags: ['GetAllAdminUsers'],
		}),
		getSingleAdminUser: builder.query<IApiResponse<IAdminUsers[]>, string>({
			query: (userId) => ({ url: `/admin/access/users/user/${userId}` }),
		}),
		getAllPermissions: builder.query<IApiResponsePaginated<IAdminPermission[]>, void>({
			query: () => ({ url: '/admin/access/permissions' }),
			providesTags: ['GetAllPermissions'],
		}),
		createPermission: builder.mutation<IApiResponse<IAdminPermission>, IPermissionPayloadType>({
			query: (payload) => ({
				url: '/admin/access/permissions',
				method: 'POST',
				body: { ...payload },
			}),
			invalidatesTags: ['GetResourcePermissions'],
		}),
		addPermissionsToRole: builder.mutation<IApiResponse, { roleId?: string; payload: string[] }>({
			query: ({ roleId, payload }) => ({
				method: 'PUT',
				url: `/admin/access/roles/role/${roleId}/permissions`,
				body: {
					_ids: payload,
				},
			}),
			invalidatesTags: ['GetAdminRoles'],
		}),
		createAdminUser: builder.mutation<
			IApiResponse<{ email: string; username: string; name: string }>,
			{ name: string; username: string; email: string; password: string }
		>({
			query: ({ name, username, email, password }) => ({
				method: 'POST',
				url: '/admin/users/admin/add',
				body: { name, username, email, password },
			}),
			invalidatesTags: ['GetAllAdminUsers'],
		}),
		updatePermission: builder.mutation<IApiResponse<IAdminPermission>, { payload: IPermissionPayloadType; id: string }>({
			query: ({ payload, id }) => ({
				url: `/admin/access/permissions/permission/${id}`,
				method: 'PUT',
				body: { ...payload },
			}),
			invalidatesTags: ['GetResourcePermissions'],
		}),
	}),
});

export const {
	useGetAdminRolesQuery,
	useGetAdminRoleDetailsQuery,
	useCreateAdminRoleMutation,
	useCreateAdminUserMutation,
	useCreatePermissionMutation,
	useAddPermissionsToRoleMutation,
	useUpdateAdminRoleMutation,
	useGetAllResourcesQuery,
	useGetResourcePermissionsQuery,
	useGetAdminUsersQuery,
	useGetSingleAdminUserQuery,
	useGetAllPermissionsQuery,
	useAssignRoleToAdminMutation,
	useRemoveAccessMutation,
	useRemoveAdminRoleMutation,
	useUpdatePermissionMutation,
} = accessApiSlice;
