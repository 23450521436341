interface Props {
	isLoginScreen?: boolean;
}

export const BreetLogo = ({ isLoginScreen }: Props) => (
	<svg
		width={!isLoginScreen ? '97' : undefined}
		height={!isLoginScreen ? '28' : undefined}
		viewBox='0 0 97 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M66.3984 24.0938H68.1335C67.8473 24.1116 67.5611 24.1295 67.257 24.1295C66.9529 24.1295 66.6667 24.1295 66.3984 24.0938Z'
			fill='#006168'
		/>
		<path
			d='M80.7031 24.0938H82.4382C82.152 24.1116 81.8658 24.1295 81.5617 24.1295C81.2576 24.1295 80.9714 24.1295 80.7031 24.0938Z'
			fill='#006168'
		/>
		<path
			d='M96.1082 24.0938C95.3927 24.1295 94.7309 24.1295 94.1406 24.0938H96.1082Z'
			fill='#006168'
		/>
		<path
			d='M31.9821 14.1487C31.6959 2.59363 28.4941 0 16.0089 0C2.86193 0 0 2.86193 0 16.0089C0 29.1381 2.86193 32 15.9911 32H16.0089C17.4578 32 18.7993 31.9642 20.0156 31.8748C29.7462 31.2309 32 27.6713 32 15.9911C32.0179 15.3471 32 14.739 31.9821 14.1487Z'
			fill='#05A2BF'
		/>
		<path
			d='M25.1461 10.3564V12.5923C25.1103 12.968 25.003 13.3436 24.8062 13.6656C24.0908 14.8282 22.4451 15.5079 21.1752 15.7941C20.6564 15.9193 20.1198 15.973 19.5832 15.973H19.5653H11.7844H8.54688V13.7371H10.1209H19.5474H19.5653C20.1019 13.7371 20.6385 13.6656 21.1573 13.5582C21.998 13.3794 22.9817 13.0037 23.7688 12.4671C24.1802 12.1809 24.5379 11.8411 24.7883 11.4297C24.842 11.3581 24.8778 11.2866 24.9136 11.1971C25.0567 10.9467 25.1282 10.6605 25.1461 10.3564Z'
			fill='#022745'
		/>
		<path
			d='M25.524 17.5293V20.0872C25.4883 20.5164 25.3631 20.9457 25.1305 21.3214C24.3077 22.6629 22.4296 23.4499 20.9628 23.7719C20.3726 23.8971 19.7465 23.9687 19.1384 23.9865H19.1205H10.1948H6.49219V21.4287H8.29878H10.1948H19.1205H19.1384C19.7465 21.4287 20.3547 21.3571 20.9628 21.214C21.9109 20.9994 23.0556 20.588 23.9679 19.9441C24.4508 19.6042 24.8443 19.2107 25.1305 18.7635C25.1842 18.6741 25.22 18.5846 25.2736 18.5131C25.4167 18.1911 25.4883 17.8692 25.524 17.5293Z'
			fill='#022745'
		/>
		<path
			d='M25.524 17.5293C25.5062 17.8691 25.4167 18.1911 25.2736 18.5131C25.2379 18.6025 25.1842 18.6919 25.1305 18.7635C24.8443 19.2286 24.4329 19.6221 23.9679 19.944C23.0735 20.5701 21.9287 20.9994 20.9628 21.1961C20.3726 21.3213 19.7465 21.4108 19.1384 21.4108H19.1205H10.1948H8.29878H6.49219L11.9299 13.7372H8.56709L13.3608 6.95801H21.9645C22.9125 6.95801 23.8248 7.3873 24.4329 8.12067C24.9159 8.72883 25.2021 9.55163 25.1305 10.3387C25.1126 10.6249 25.0411 10.9289 24.9159 11.1972C24.8801 11.2688 24.8443 11.3403 24.7907 11.4298C24.5403 11.8233 24.1825 12.181 23.7711 12.4672C22.9841 13.0217 21.9824 13.3795 21.1596 13.5583C21.0165 13.5941 20.8734 13.612 20.7482 13.6478C21.4279 13.6478 21.8751 13.6478 21.8751 13.6478C22.9662 13.6478 24.0215 14.1307 24.7012 14.9893C25.2736 15.6869 25.5956 16.6349 25.524 17.5293Z'
			fill='white'
		/>
		<path
			d='M50.6053 16.5816C50.1044 15.7946 49.4247 15.2759 48.5483 15.0254C49.3174 14.8466 49.8898 14.3994 50.2654 13.7018C50.641 13.0042 50.8378 12.1814 50.8378 11.287C50.8378 9.98128 50.3191 8.94383 49.2637 8.1568C48.2263 7.38766 46.5986 6.99414 44.4342 6.99414H37.7266V24.1479H44.5415C46.9742 24.1479 48.7092 23.6828 49.7825 22.7527C50.8378 21.8225 51.3744 20.6241 51.3744 19.1574C51.3565 18.2272 51.1061 17.3686 50.6053 16.5816ZM40.66 9.42678H44.5237C45.8473 9.42678 46.7417 9.64143 47.2246 10.0528C47.6897 10.4642 47.9401 11.0903 47.9401 11.8952C47.9401 13.505 46.8669 14.31 44.7025 14.31H40.6779V9.42678H40.66ZM47.5824 21.0534C47.0815 21.5006 46.0977 21.7152 44.631 21.7152H40.66V16.5637H44.6846C45.2213 16.5637 45.6684 16.5816 46.0441 16.6353C46.4197 16.6889 46.7953 16.7963 47.1709 16.9751C47.5466 17.154 47.8328 17.4044 48.0295 17.7622C48.2263 18.1199 48.3157 18.5671 48.3157 19.1037C48.3336 19.9623 48.0832 20.6062 47.5824 21.0534Z'
			fill={!isLoginScreen ? 'white' : 'currentColor'}
		/>
		<path
			d='M59.619 10.4105C58.4385 10.4105 57.4905 10.6072 56.775 11.0186C56.0595 11.43 55.5766 11.8951 55.3082 12.4317L54.6643 10.5893H53.1797V24.0941H55.9701V16.5278C55.9701 15.3115 56.2026 14.3814 56.6677 13.7553C57.1327 13.1293 58.0092 12.8252 59.315 12.8252C59.3865 12.8252 59.5117 12.8252 59.6727 12.8431C59.8516 12.861 59.9768 12.861 60.0662 12.861V10.3926H59.619V10.4105Z'
			fill={!isLoginScreen ? 'white' : 'currentColor'}
		/>
		<path
			d='M70.3155 19.8548C69.9756 21.2858 68.9024 21.9834 67.1316 21.9834C64.8242 21.9834 63.5899 20.7671 63.429 18.3523H73.1774C73.249 17.4759 73.249 16.6709 73.1595 15.9197C73.088 15.1684 72.9091 14.4351 72.6408 13.7553C72.3725 13.0756 72.0148 12.4854 71.5497 11.9845C71.0846 11.5016 70.4944 11.1081 69.7431 10.8219C69.0097 10.5357 68.1511 10.3926 67.2031 10.3926C62.7492 10.3926 60.5312 12.6642 60.5312 17.2254C60.5312 21.5183 62.4809 23.8079 66.3982 24.0762C66.6665 24.0941 66.9527 24.112 67.2389 24.112C67.543 24.112 67.8471 24.0941 68.1154 24.0762C69.6358 23.9689 70.7984 23.5932 71.6034 22.9135C72.5514 22.1265 73.088 21.0891 73.2311 19.8191H70.3155V19.8548ZM66.9527 12.5748C68.1333 12.5748 68.9918 12.9147 69.5285 13.5765C70.0651 14.2562 70.3334 15.079 70.3155 16.0986C70.3334 16.0986 70.3334 16.0986 70.3155 16.1164C70.3334 16.1343 70.3334 16.1522 70.3155 16.1522H63.4647C63.6078 13.7732 64.7705 12.5748 66.9527 12.5748Z'
			fill={!isLoginScreen ? 'white' : 'currentColor'}
		/>
		<path
			d='M84.628 19.8548C84.2881 21.2858 83.2149 21.9834 81.4441 21.9834C79.1367 21.9834 77.9024 20.7671 77.7415 18.3523H87.4899C87.5615 17.4759 87.5615 16.6709 87.472 15.9197C87.4005 15.1684 87.2216 14.4351 86.9533 13.7553C86.685 13.0756 86.3273 12.4854 85.8622 11.9845C85.3971 11.5016 84.8069 11.1081 84.0556 10.8219C83.3222 10.5357 82.4636 10.3926 81.5156 10.3926C77.0617 10.3926 74.8438 12.6642 74.8438 17.2254C74.8438 21.5183 76.7934 23.8079 80.7107 24.0762C80.979 24.0941 81.2652 24.112 81.5514 24.112C81.8555 24.112 82.1596 24.0941 82.4279 24.0762C83.9483 23.9689 85.1109 23.5932 85.9159 22.9135C86.8639 22.1265 87.4005 21.0891 87.5436 19.8191H84.628V19.8548ZM81.2652 12.5748C82.4458 12.5748 83.3043 12.9147 83.841 13.5765C84.3776 14.2562 84.6459 15.079 84.628 16.0986C84.6459 16.0986 84.6459 16.0986 84.628 16.1164C84.6459 16.1343 84.6459 16.1522 84.628 16.1522H77.7772C77.9382 13.7732 79.1009 12.5748 81.2652 12.5748Z'
			fill={!isLoginScreen ? 'white' : 'currentColor'}
		/>
		<path
			d='M96.2737 12.879V10.6431H92.7857V6.99414H90.0132V10.6431L88.1172 11.2155V12.879H90.0132V20.159C90.0132 21.7331 90.4962 22.8063 91.48 23.4145C92.106 23.808 93.0004 24.0405 94.163 24.1121C94.7533 24.1479 95.3972 24.1479 96.1306 24.1121C96.1843 24.1121 96.2379 24.1121 96.2916 24.1121V21.8762C94.9143 21.9477 94.002 21.8583 93.5549 21.59C93.1077 21.3217 92.8751 20.8566 92.8751 20.1769V12.8969H96.2737V12.879Z'
			fill={!isLoginScreen ? 'white' : 'currentColor'}
		/>
	</svg>
);
