import { type RouteObject } from 'react-router-dom';

const accessMgtRoutes: RouteObject[] = [
	{
		path: 'access-management',
		children: [
			{
				path: 'admin-management',
				children: [
					{
						index: true,
						lazy: () => import('../features/access-management/admin-management'),
					},
				],
			},
			{
				path: 'permission-resources',
				children: [
					{
						index: true,
						lazy: () => import('../features/access-management/permission-resources'),
					},
					{
						path: 'details',
						lazy: () => import('../features/access-management/permission-resources/resource-permissions'),
					},
				],
			},
			{
				path: 'roles-and-permission',
				children: [
					{
						index: true,
						lazy: () => import('../features/access-management/roles-and-permission'),
					},
					{
						path: ':roleId',
						children: [
							{
								index: true,
								lazy: () => import('../features/access-management/roles-and-permission/[roleId]'),
							},
							{
								path: 'permissions',
								lazy: () => import('../features/access-management/roles-and-permission/[roleId]/permissions'),
							},
						],
					},
				],
			},
		],
	},
];

export default accessMgtRoutes;
