import { ButtonVariant } from 'BreetComponents';
import * as yup from 'yup';

export const AdminRoleFormSchema = yup.object({
	name: yup.string().required('This is required'),
	description: yup.string().required('Please provide a reason'),
});

export type AdminRoleFormSchemaType = yup.InferType<typeof AdminRoleFormSchema>;

export interface RoleActionPopoverProps {
	triggerVariant?: ButtonVariant;
	roleId?: string;
	name?: string;
	description?: string;
}
