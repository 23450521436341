import { Button, Loader } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { capitalizeFirstLetter, dateConst, spawnAppToast } from 'BreetHelpers';
import { openModal, selectModals, useAppDispatch, useAppSelector } from 'BreetRedux';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';

import { LoaderIcon } from '@/assets/icons';
import { useGetSingleAdminUserQuery, useRemoveAdminRoleMutation } from '@/redux/slices/api/access.api';

import { fullNameIntials, transformedAdminUserDataToObject } from './AddAdmin.helpers';

const AdminUserDetails = () => {
	const modals = useAppSelector(selectModals);
	const currentModal = modals.find((modal) => modal.id === 'showAdminUserDetails');
	const userId = currentModal?.metadata as string;
	const dispatch = useAppDispatch();
	const { data: adminUserData, isFetching } = useGetSingleAdminUserQuery(userId, { refetchOnMountOrArgChange: true });
	const adminUserObject = transformedAdminUserDataToObject(adminUserData?.data ?? []);
	const adminUser = adminUserObject[userId];

	const [removeAdminRole, { isLoading: removingAdminRole }] = useRemoveAdminRoleMutation();

	const handleAssignRoleModal = useCallback(() => {
		if (!userId || !adminUserData?.data) return;
		dispatch(
			openModal({
				id: 'assignAdminRole',
				header: 'Add a Role',
				metadata: { userId, hasRole: true },
			})
		);
		closeModalById('showAdminUserDetails');
	}, [adminUserData?.data, dispatch, userId]);

	const handleRemoveAdminRole = useCallback(() => {
		removeAdminRole({ adminId: [userId], roleId: adminUser?.role._id ?? '' })
			.unwrap()
			.then((resp) => {
				spawnAppToast({ dataMsg: resp, type: 'success' });
				closeModalById('showAdminUserDetails');
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [adminUser?.role._id, removeAdminRole, userId]);

	if (isFetching) {
		return <Loader />;
	}

	if (adminUserData?.data?.length === 0) {
		return (
			<div className='no_data_block align-flex-center'>
				<LoaderIcon />
				<span>No Data</span>
			</div>
		);
	}
	return (
		<div className='adminUserDetails'>
			<div className='adminUserDetails__content'>
				<article>
					<div className='adminUserDetails__nameInitials__bg'>
						<div className='adminUserDetails__nameInitials'>{fullNameIntials(adminUser?.userInfo.name ?? '')}</div>
					</div>
					<div className='adminUserDetails__info'>
						<div className='adminUserDetails__user'>
							<span>{adminUser?.userInfo.name ?? ''}</span>
							<span>{capitalizeFirstLetter(adminUser?.role.type ?? '')}</span>
						</div>
						<div className='adminUserDetails__infoRecords'>
							<div>
								<span>Permissions</span>
								<span className='adminUserDetails__info__permissions'>{adminUser?.permissions.length ?? 0}</span>
							</div>
							<div>
								<span>Date Created</span>
								<span className='adminUserDetails__info__dateTime'>
									{format(parseISO(adminUser?.createdAt ?? ''), dateConst.FULL_DATE_TIME_FORMAT2)}
								</span>
							</div>
							<div>
								<span>Last Updated</span>
								<span className='adminUserDetails__info__dateTime'>
									{format(parseISO(adminUser?.updatedAt ?? ''), dateConst.FULL_DATE_TIME_FORMAT2)}
								</span>
							</div>
						</div>
					</div>
					<div className='adminUserDetails__ctas'>
						<Button
							variant='red'
							size='regular'
							onClick={handleRemoveAdminRole}
							loading={removingAdminRole}
						>
							Remove Role
						</Button>
						<Button
							variant='blue'
							size='regular'
							onClick={handleAssignRoleModal}
						>
							Change Role
						</Button>
					</div>
				</article>
			</div>
		</div>
	);
};

export default AdminUserDetails;
