export const EditIcon = ({ color = '#393939' }: { color?: string }) => (
	<svg
		width='14'
		height='14'
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7.4586 1.68665C6.36887 1.59318 5.27675 1.837 4.33025 2.38507C3.38374 2.93314 2.62862 3.75896 2.16723 4.75061C1.70584 5.74226 1.56049 6.85179 1.75087 7.92882C1.94125 9.00586 2.45816 9.99832 3.23155 10.7717C4.00494 11.5451 4.9974 12.062 6.07443 12.2524C7.15147 12.4428 8.261 12.2974 9.25265 11.836C10.2443 11.3746 11.0701 10.6195 11.6182 9.67301C12.1663 8.72651 12.4101 7.63439 12.3166 6.54465C12.2699 5.98452 12.1342 5.43541 11.9146 4.91799L12.9146 3.91665C13.4125 4.86825 13.6715 5.92667 13.6693 7.00065C13.6693 10.6827 10.6846 13.6673 7.0026 13.6673C3.3206 13.6673 0.335938 10.6827 0.335938 7.00065C0.335938 3.31865 3.3206 0.333987 7.0026 0.333987C8.07644 0.333033 9.13455 0.591957 10.0866 1.08865L9.08594 2.08865C8.56849 1.86915 8.0194 1.73345 7.45927 1.68665H7.4586ZM12.6593 0.400654L13.6026 1.34399L7.4746 7.47199L6.53327 7.47399L6.53194 6.52932L12.6593 0.400654Z'
			fill={color}
		/>
	</svg>
);
