import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast, validateKeyDown } from 'BreetHelpers';
import { useCurrencyOutput } from 'BreetHooks';
import { selectModals, useAppSelector, useUpdateUserRewardPointsMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import type { AddRemoveUserPointSchemaType } from './userActions.helpers';
import { AddRemoveUserPointSchema } from './userActions.helpers';

const AddOrRemoveUserPointsBalance = () => {
	const modals = useAppSelector(selectModals);
	const currencyOutput = useCurrencyOutput();

	const currentModal = modals.find((modal) => modal.id === 'addRemoveUserPointBalance');
	const modalMetadata = currentModal?.metadata as Record<string, string | number | Record<string, string | number>> | undefined;
	const isAddingPoints = !!modalMetadata?.isAdding;
	const userId = modalMetadata?.userId as string;
	const rewardPoints = modalMetadata?.rewardPoints as Record<string, string>;

	const [updateRewardPoints, { isLoading: isUpdatingUser }] = useUpdateUserRewardPointsMutation();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver(AddRemoveUserPointSchema),
	});

	const onSubmit = useCallback(
		(payload: AddRemoveUserPointSchemaType) => {
			updateRewardPoints({ userId, isAddingPoints, ...payload })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('addRemoveUserPointBalance');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[updateRewardPoints, isAddingPoints, userId]
	);

	const handlePointsInputChange = useCallback(
		(value: string) => {
			const rawValue = value.replace(/,/g, ''); // Remove commas for validation
			setValue('points', Number(rawValue));
		},
		[setValue]
	);

	const currentValues = watch();

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Input
				label='Current Points Balance'
				placeholder='0.00'
				extValue={currencyOutput(rewardPoints.balance as string | undefined)}
				disabled
			/>
			<Input
				label={`${isAddingPoints ? 'Add to' : 'Remove'} Balance`}
				placeholder='0.00'
				inputMode='numeric'
				extValue={currencyOutput(currentValues.points)}
				onChange={handlePointsInputChange}
				onKeyDown={validateKeyDown(/^[0-9,]$/)}
				errorMsg={errors.points?.message}
			/>
			<TextareaInput
				label='Description'
				placeholder='Description'
				rows={3}
				register={register('reason')}
				errorMsg={errors.reason?.message}
			/>
			<Button
				size='large'
				variant={isAddingPoints ? 'main' : 'red'}
				loading={isUpdatingUser}
			>
				{isAddingPoints ? 'Add' : 'Remove'}
			</Button>
		</form>
	);
};

export default AddOrRemoveUserPointsBalance;
