import { useCallback, useEffect, useMemo, useState } from 'react';

import { type CheckboxProps, type CheckboxStateType } from './Checkbox.helpers';

const Checkbox = ({
	checked = false,
	disabled = false,
	wrapperClassname = '',
	label = '',
	onChange,
	onClick,
	state,
	id,
}: CheckboxProps) => {
	const [isChecked, setIsChecked] = useState(checked);

	const checkboxState = useMemo<CheckboxStateType>(() => {
		if (disabled) return 'disabled';
		if (state) return state;
		if (isChecked) return 'fullCheck';
		return 'uncheck';
	}, [isChecked, disabled, state]);

	const handleCheckboxClick = useCallback(() => {
		if (disabled) return;

		onClick?.();
	}, [disabled, onClick]);

	const handleCheckboxChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (disabled) return;
			setIsChecked(e.target.checked);
			onChange?.(e.target.checked);
		},
		[disabled, onChange]
	);
	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);
	return (
		<button
			className={`bt_checkbox ${checkboxState} ${wrapperClassname}`}
			onClick={handleCheckboxClick}
			type='button'
		>
			<input
				checked={isChecked}
				onChange={handleCheckboxChange}
				className='bt_checkbox_input'
				id={`bt_checkbox-${id}`}
				type='checkbox'
				disabled={disabled}
			/>
			<label
				className='bt_checkbox_label'
				htmlFor={`bt_checkbox-${id}`}
			>
				<span className={`bt_checkbox_label_content ${checkboxState}`}>-</span>
				{label && <span>{label}</span>}
			</label>
		</button>
	);
};

export default Checkbox;
