import { type IAdminUsers } from 'BreetHelpers';
import * as yup from 'yup';

export interface AddAdminFormSchemaType {
	name: string;
	username: string;
	email: string;
	password: string;
	confirmPassword: string; // Optional, not part of the API payload
}

export const AddAdminFormSchema = yup.object({
	name: yup.string().required('Please provide a name for this admin'),
	email: yup.string().required('Email address is required'),
	username: yup.string().required('Please provide a username'),
	password: yup.string().required('Kindly provide password'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password')], 'Password must match!')
		.required('Confirm password is required'),
});

export const fullNameIntials = (fullName: string) =>
	fullName
		.toUpperCase()
		.split(' ') // split the names into an array of words
		.map((item) => item[0]) // Take first letter of each word
		.join(''); // join to form the initials

export const transformedAdminUserDataToObject = (data: IAdminUsers[]): Record<string, IAdminUsers | undefined> =>
	data.reduce((acc: Record<string, IAdminUsers>, item: IAdminUsers) => {
		acc[item.user] = item;
		return acc;
	}, {});
