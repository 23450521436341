import { store, usersApiSlice } from 'BreetRedux';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { UserMgtUsersLayout } from '@/layout';

const userMgtRoutes: RouteObject[] = [
	{
		path: 'user-management',
		children: [
			{
				path: 'users',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/users'),
					},
					{
						path: ':userId',
						element: (
							<UserMgtUsersLayout>
								<Outlet />
							</UserMgtUsersLayout>
						),
						children: [
							{
								path: 'details',
								lazy: () => import('../features/user-management/users/[userId]/details'),
							},
							{
								path: 'kyc',
								lazy: () => import('../features/user-management/users/[userId]/kyc'),
							},
							{
								path: 'edit',
								lazy: () => import('../features/user-management/users/[userId]/edit'),
							},
							{
								path: 'summary',
								lazy: () => import('../features/user-management/users/[userId]/summary'),
							},
							{
								path: 'fiat-wallet',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/fiat-wallet'),
									},
									{
										path: ':fiatId',
										lazy: () => import('../features/user-management/users/[userId]/fiat-wallet/[fiatId]'),
									},
								],
							},
							{
								path: 'crypto-wallet',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/crypto-wallet'),
									},
									{
										path: ':cryptoWalletId',
										lazy: () => import('../features/user-management/users/[userId]/crypto-wallet/[cryptoWalletId]'),
									},
								],
							},
							{
								path: 'accounts',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/accounts'),
									},
									{
										path: ':accountId',
										lazy: () => import('../features/user-management/users/[userId]/accounts/[accountId]'),
									},
								],
							},
							{
								path: 'pr-logs',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/pr-logs'),
									},
									{
										path: ':txReference',
										lazy: () => import('../features/user-management/users/[userId]/pr-logs/[txReference]'),
									},
								],
							},
							{
								path: 'ngn-withdrawals',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/ngn-withdrawals'),
									},
									{
										path: ':txReference',
										lazy: () => import('../features/user-management/users/[userId]/ngn-withdrawals/[txReference]'),
									},
								],
							},
							{
								path: 'ghs-withdrawals',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/ghs-withdrawals'),
									},
									{
										path: ':txReference',
										lazy: () => import('../features/user-management/users/[userId]/ghs-withdrawals/[txReference]'),
									},
								],
							},
							{
								path: 'note-history',
								loader: async ({ params }) => {
									const promise = store.dispatch(usersApiSlice.endpoints.getUserNoteHistory.initiate({ userId: params.userId }));
									const { data } = await promise;

									return data ?? null;
								},
								lazy: () => import('../features/user-management/users/[userId]/note-history'),
							},
							{
								path: 'bills',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/bills'),
									},
									{
										path: ':txReference',
										lazy: () => import('../features/user-management/users/[userId]/bills/[txReference]'),
									},
								],
							},
							{
								path: 'crypto-invoice',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/crypto-invoice'),
									},
									{
										path: ':txReference',
										lazy: () => import('../features/user-management/users/[userId]/crypto-invoice/[txReference]'),
									},
								],
							},
							{
								path: 'crypto-trades',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/crypto-trades'),
									},
									{
										path: ':txReference',
										lazy: () => import('../features/user-management/users/[userId]/crypto-trades/[txReference]'),
									},
								],
							},
							{
								path: 'swap-crypto',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/swap-crypto'),
									},
									{
										path: ':txReference',
										lazy: () => import('../features/user-management/users/[userId]/swap-crypto/[txReference]'),
									},
								],
							},
							{
								path: 'referrals',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/referrals'),
									},
									{
										path: ':referralId',
										lazy: () => import('../features/user-management/users/[userId]/referrals/[referralId]'),
									},
								],
							},
							{
								path: 'actions',
								children: [
									{
										index: true,
										lazy: () => import('../features/user-management/users/[userId]/actions'),
									},
									{
										path: ':actionId',
										lazy: () => import('../features/user-management/users/[userId]/actions/[actionId]'),
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'kyc',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/kyc'),
					},
					{
						path: ':kycId',
						lazy: () => import('../features/user-management/kyc/[kycId]'),
					},
				],
			},
			{
				path: 'aml-screening',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/aml-screening'),
					},
					{
						path: ':screeningId',
						lazy: () => import('../features/user-management/aml-screening/[screeningId]'),
					},
				],
			},
			{
				path: 'bank-accounts',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/bank-accounts'),
					},
					{
						path: ':accountId',
						lazy: () => import('../features/user-management/bank-accounts/[accountId]'),
					},
				],
			},
			{
				path: 'crypto-wallets',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/crypto-wallets'),
					},
					{
						path: ':cryptoWalletId',
						lazy: () => import('../features/user-management/crypto-wallets/[cryptoWalletId]'),
					},
				],
			},
			{
				path: 'user-wallet-balance',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/user-wallet-balance'),
					},
					{
						path: ':userId',
						lazy: () => import('../features/user-management/user-wallet-balance/[userId]'),
					},
				],
			},
			{
				path: 'point-reward-log',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/point-reward-log'),
					},
					{
						path: ':txReference',
						lazy: () => import('../features/user-management/point-reward-log/[txReference]'),
					},
				],
			},
			{
				path: 'referrals',
				children: [
					{
						index: true,
						lazy: () => import('../features/user-management/referrals'),
					},
					{
						path: ':referralId',
						lazy: () => import('../features/user-management/referrals/[referralId]'),
					},
				],
			},
		],
	},
];
export default userMgtRoutes;
